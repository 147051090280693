import React, {
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";

import Header from './header'
import Footer from './footer'
import PricingTable from './pricingtable'
import Card from './card'


const Home = () => {

    useEffect(()=>{
          document.title = 'FACEVIEW';
          window.scrollTo(0,0);
    },[])

    return (
        <div className="nk-app-root ">
                <Header/>

                <main className="nk-pages">
                    <section className="section section-bottom-0 has-shape">
                        <div className="nk-shape bg-shape-blur-a mt-8 start-50 top-0 translate-middle-x"></div>
                        <div className="container">
                            <div className="section-head">
                                <div className="row justify-content-center text-center">
                                    <div className="col-lg-9 col-xl-8 col-xxl-7">
                                        <h6 className="overline-title text-primary">Get started for free</h6>
                                        <h2 className="title">Interviews Without The Need For Candidate's Real Time Availability</h2>
                                        <p className="lead">No more scheduling hassles. With Faceview, interviews adapt to your own schedule.
                                        Candidates can participate at their own convenience</p>
                                    </div>
                                </div>
                            </div>
                            <div className="section-content">
                                <div className="row text-center g-gs">
                                    <div className="col-md-6 col-xl-4">
                                        <div className="card rounded-4 border-0 shadow-tiny h-100">
                                            <div className="card-body">
                                                <div className="feature">
                                                    <div className="feature-media">
                                                        <div className="media media-middle media-xl text-bg-primary-soft rounded-4">
                                                            <em className="icon ni ni-grid-plus"></em>
                                                        </div>
                                                    </div>
                                                    <div className="feature-text">
                                                        <h4 className="title">Your Own Job Board</h4>
                                                        <p>A built-in Job Board, allowing candidates to apply using an application form.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xl-4">
                                        <div className="card rounded-4 border-0 shadow-tiny h-100">
                                            <div className="card-body">
                                                <div className="feature">
                                                    <div className="feature-media">
                                                        <div className="media media-middle media-xl text-bg-primary-soft rounded-4">
                                                            <em className="icon ni ni-video"></em>
                                                        </div>
                                                    </div>
                                                    <div className="feature-text">
                                                        <h4 className="title">Create Interviews </h4>
                                                        <p>Start an interview by providing a title, and proceed to craft a set of questions</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xl-4">
                                        <div className="card rounded-4 border-0 shadow-tiny h-100">
                                            <div className="card-body">
                                                <div className="feature">
                                                    <div className="feature-media">
                                                        <div className="media media-middle media-xl text-bg-primary-soft rounded-4">
                                                            <em className="icon ni ni-setting"></em>
                                                        </div>
                                                    </div>
                                                    <div className="feature-text">
                                                        <h4 className="title">AI Generated Questions</h4>
                                                        <p>Need to write the interview questions? Our AI will assist writing the questions for you</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xl-4">
                                        <div className="card rounded-4 border-0 shadow-tiny h-100">
                                            <div className="card-body">
                                                <div className="feature">
                                                    <div className="feature-media">
                                                        <div className="media media-middle media-xl text-bg-primary-soft rounded-4">
                                                            <em className="icon ni ni-users"></em>
                                                        </div>
                                                    </div>
                                                    <div className="feature-text">
                                                        <h4 className="title">Convenient Participation</h4>
                                                        <p>Candidates respond at their convenience, without the need for real-time availability</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-md-6 col-xl-4">
                                        <div className="card rounded-4 border-0 shadow-tiny h-100">
                                            <div className="card-body">
                                                <div className="feature">
                                                    <div className="feature-media">
                                                        <div className="media media-middle media-xl text-bg-primary-soft rounded-4">
                                                            <em className="icon ni ni-bulb"></em>
                                                        </div>
                                                    </div>
                                                    <div className="feature-text">
                                                        <h4 className="title">Better Decisions</h4>
                                                        <p>Make better decisions by reviewing the recordings at your convenience</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xl-4">
                                        <div className="card rounded-4 border-0 shadow-tiny h-100">
                                            <div className="card-body">
                                                <div className="feature">
                                                    <div className="feature-media">
                                                        <div className="media media-middle media-xl text-bg-primary-soft rounded-4">
                                                            <em className="icon ni ni-share"></em>
                                                        </div>
                                                    </div>
                                                    <div className="feature-text">
                                                        <h4 className="title">Team Collaboration</h4>
                                                        <p>Our commenting and sharing features make it easy to stay on top of your projects</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section has-mask">
                        <div className="nk-mask bg-pattern-dot bg-blend-around mt-10p mb-3"></div>
                        <div className="container">
                            <div className="section-head">
                                <div className="row justify-content-center text-center">
                                    <div className="col-lg-9 col-xl-8 col-xxl-7">
                                        <h6 className="overline-title text-primary">How it works</h6>
                                        <h2 className="title">Discover How Faceview Works</h2>
                                        <p className="lead">Our platform enables you to create video interviews and sending them to candidates, empowering them to respond by recorded videos</p>
                                    </div>
                                </div>
                            </div>
                            <div className="section-content">
                                <div className="row g-gs">
                                    <div className="col-lg-4">
                                        <div className="feature feature-inline">
                                            <div className="feature-text me-auto">
                                                <h4 className="title">Create a Job</h4>
                                                <p>
                                                  Create a job effortlessly by providing a job title and description, or opt for convenience by selecting a premium template from our collection </p>
                                            </div>
                                            <div className="feature-decoration flex-shrink-0 ms-4">
                                                <img src="images/number/1.png" alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="feature feature-inline">
                                            <div className="feature-text me-auto">
                                                <h4 className="title">Send To Candidates</h4>
                                                <p>Once you've created the job and reviewed the interview questions, simply hit the publish button and add the potential candidates </p>
                                            </div>
                                            <div className="feature-decoration flex-shrink-0 ms-4">
                                                <img src="images/number/2.png" alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="feature feature-inline">
                                            <div className="feature-text me-auto">
                                                <h4 className="title">Receive Video Recordings</h4>
                                                <p>Effortlessly Receive video recordings of the candidates, gaining valuable answers and insights to make better decisions</p>
                                            </div>
                                            <div className="feature-decoration flex-shrink-0 ms-4">
                                                <img src="images/number/3.png" alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="section-actions text-center">
                                <ul className="btn-list btn-list-inline gx-gs gy-3">
                                    <li><a href="//app.faceview.io/trial" className="btn btn-primary btn-lg"><span>Sign Up For Free</span></a></li>
                                    {/*<li><a href="#" className="btn btn-primary btn-soft btn-lg"><em className="icon ni ni-play"></em><span>See action</span></a></li>*/}
                                </ul>
                            </div>
                            <div className="section-content">
                                <div className="row gx-5 gy-6 align-items-center">
                                    <div className="col-lg-6 col-xl-6">
                                        <div className="block-gfx pe-xl-5 pe-lg-3">
                                            <img className="w-100 rounded-4" src="images/gfx/feature/a.jpg" alt=""/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-6">
                                        <div className="block-text">
                                            <h2 className="title">AI Generated Questions In Seconds</h2>
                                            <p>Generate questions for the interviews with Artificial Intelligence.</p>
                                            <ul className="list gy-3">
                                                <li><em className="icon ni ni-minus text-primary"></em><span>Create an interview in less than 15 seconds.</span></li>
                                                <li><em className="icon ni ni-minus text-primary"></em><span>Save hundreds of hours with our AI question generator.</span></li>
                                                <li><em className="icon ni ni-minus text-primary"></em><span>Modify the questions with the powerful editor.</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section section-bottom-0 section-top-0">
                        <div className="container">
                            <div className="section-head">
                                <div className="row justify-content-center text-center">
                                    <div className="col-lg-9 col-xl-7 col-xxl-6">
                                        <h6 className="overline-title text-primary">Pricing</h6>
                                        <h2 className="title">Plans That Best Suit Your Needs</h2>
                                        <p className="lead px-lg-10 px-xxl-9">With our simple plans, supercharge your team that helps your business.</p>
                                    </div>
                                </div>
                            </div>
                            <PricingTable/>
                        </div>
                    </section>
                    <section className="section section-bottom-0">
                        <div className="container">
                            <div className="section-head">
                                <div className="row justify-content-center text-center">
                                    <div className="col-xl-8">
                                        <h2 className="title">Frequently Asked Questions</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="section-content">
                                <div className="row g-gs justify-content-center">
                                    <div className="col-xl-9 col-xxl-8">
                                        <div className="accordion accordion-flush accordion-plus-minus accordion-icon-accent" id="faq-1">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button" data-bs-toggle="collapse" data-bs-target="#faq-1-1"> What makes Faceview platform unique? </button>
                                                </h2>
                                                <div id="faq-1-1" className="accordion-collapse collapse show" data-bs-parent="#faq-1">
                                                   <div className="accordion-body"> Our platform stands out with features like AI-generated questions, allowing for a more tailored and efficient interviews. We also excel in handling interviews at Scale, making it easy to manage large teams seamlessly. </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#faq-1-2"> How does AI-generated questions benefit my interviews? </button>
                                                </h2>
                                                <div id="faq-1-2" className="accordion-collapse collapse" data-bs-parent="#faq-1">
                                                    <div className="accordion-body"> AI-generated questions ensure consistency and relevance in interviews. The system generates questions based on the interview title and description, saving your time and resources. </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#faq-1-3">  Can I organize interviews into projects? </button>
                                                </h2>
                                                <div id="faq-1-3" className="accordion-collapse collapse" data-bs-parent="#faq-1">
                                                    <div className="accordion-body"> Absolutely! You can organize related interviews into projects, making it easy to categorize, track progress, and manage multiple interviews simultaneously. This feature enhances overall organization and workflow efficiency. </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#faq-1-4">  Is it possible to share and comment on interview recordings? </button>
                                                </h2>
                                                <div id="faq-1-4" className="accordion-collapse collapse" data-bs-parent="#faq-1">
                                                    <div className="accordion-body"> Yes, our platform facilitates seamless communication by enabling sharing and commenting on interview recordings. This ensures transparent collaboration and effective feedback exchange among team members. </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Card/>
                </main>
                <Footer/>



            </div>
    )
}

export default Home;