import React, {
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";

import { Link,} from "react-router-dom";
import CookieConsent from "react-cookie-consent";

const Footer = () => {

    return (
                <>
                <footer className="nk-footer">
                    <div className="section">
                        <div className="container">
                            <div className="row g-5">
                                <div className="col-xl-3 col-sm-4 col-6">
                                    <div className="wgs">
                                        <h6 className="wgs-title overline-title text-heading mb-3">Use Cases</h6>
                                        <ul className="list gy-2 list-link-base">
                                            <li><Link className="link-base" to="/usecases">Job Interviews</Link></li>
                                            <li><Link className="link-base" to="/usecases">Initial Screenings</Link></li>
                                            <li><Link className="link-base" to="/usecases">Video Auditions</Link></li>
                                            <li><Link className="link-base" to="/usecases">Ask an Expert</Link></li>
                                            <li><Link className="link-base" to="/usecases">Status Calls</Link></li>

                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-sm-4 col-6">
                                    <div className="wgs">
                                        <h6 className="wgs-title overline-title text-heading mb-3">About</h6>
                                        <ul className="list gy-2 list-link-base">
                                            <li><Link className="link-base" to="/company">Company</Link></li>
                                            <li><Link className="link-base" to="//app.faceview.io/sign-in">Login</Link></li>
                                            <li><Link className="link-base" to="//app.faceview.io/trial">Sign up</Link></li>
                                            <li><Link className="link-base" to="/privacy">Privacy Policy</Link></li>
                                            <li><Link className="link-base" to="/terms">Terms of Use</Link></li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-xl-2 col-sm-4 col-6">
                                    <div className="wgs">
                                        <h6 className="wgs-title overline-title text-heading mb-3">Faceview</h6>
                                        <ul className="list gy-2 list-link-base">
                                            <li><Link className="link-base" to="/contact">Contact Us</Link></li>
                                            <li><Link className="link-base" to="/faq">FAQs</Link></li>
                                            <li><Link className="link-base" to="https://www.youtube.com/@faceviewofficial">YouTube</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-7 col-md-9 me-auto order-xl-first">
                                    <div className="block-text">
                                        <Link href="index.html" className="logo-link mb-4">
                                            <div className="logo-wrap">
                                                <img width="140" className="logo-img logo-dark" src="images/logo-dark-full.png"  alt=""/>
                                            </div>
                                        </Link>
                                        <p>
                                            Ask Anytime, Answer Anytime! Our platform empowers you to ask, answer, and collaborate without the need for
                                            real time availability. Beyond Schedules, Beyond Timezones
                                        </p>
                                        <p className="text-heading mt-4">&copy; 2024 faceview.io</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

                <CookieConsent
                  location="bottom"
                  buttonText="I Understand"
                  cookieName="myAwesomeCookieName2"
                  style={{ background: "#3f3eed",  }}
                  buttonStyle={{ borderRadius: '5px', background: "#f7e55e", color: "#333", fontSize: "14px" }}
                  expires={150}
                >
                  We use cookies to enhance your browsing experience. By clicking "I Understand" button, you consent to our use of
                  cookies. <a style={{color: '#fff', textDecoration: 'underline'}} href="privacy"> Privacy Policy </a>
                </CookieConsent>

                </>

    )
}

export default Footer;