import React, {
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import TypeWriterEffect from 'react-typewriter-effect';
import './styles.css';

const Hero = () => {



    return (

            <div className="nk-hero pt-xl-5 ">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 col-xl-10 col-xxl-9">
                            <div className="nk-hero-content py-5 py-lg-6">
                            <div className="d-block d-sm-none text-center">
                                <h1 className="title mb-3 mb-lg-4">Transform Your Hiring With One-way Video Interviews </h1>
                                <p className="lead mb-4 mb-lg-5 text-gray-1000">Our innovative platform enables you to pre-craft interview questions and send them to candidates, who can record their video responses on their own time</p>
                            </div>

                             <h1 className="d-none d-md-block title mb-3 mb-lg-4">Transform Your <TypeWriterEffect startDelay={100}
                                                                                          textStyle={{
                                                                                              color: 'blue',
                                                                                              display: 'inline'
                                                                                          }}
                                                                                          cursorColor="blue"
                                                                                          multiText={[
                                                                                            'Hiring',
                                                                                            'Screening',
                                                                                            'Audition',
                                                                                            'Casting'
                                                                                          ]}
                                                                                          multiTextDelay={5000}
                                                                                          typeSpeed={160}
                                                                                          multiTextLoop={true}
                                                                                        /> <br/> With One-way Video Interviews </h1>

                                <p className="lead d-none d-md-block mb-4 mb-lg-5 text-gray-1000">Our innovative platform enables you to pre-craft interview questions and send them to candidates, who can record their video responses on their own time</p>

                                <div className="text-center">
                                    <ul className="btn-list btn-list-inline">
                                        <li><a target="_new" href="//meet.faceview.io/preview/44dbd387-da50-42c4-b433-a2b4893c1eb1" className="btn btn-success bg-green btn-lg text-white text-bold"><span>View a Sample Interview</span><em className="icon ni ni-arrow-long-right"></em></a></li>

                                    </ul>
                                    <p className="sub-text mt-2"><em className="icon ni ni-check-circle-fill text-success"></em> No credit card required</p>
                                </div>
                            </div>
                            <div className="nk-hero-gfx">
                               <img className="w-100 rounded-top-4" src="images/gfx/banner/a1.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    )
}

export default Hero;