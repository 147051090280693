import React, {
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";

import { Link,} from "react-router-dom";

const NavBar = () => {

    return (

                    <div className="nk-header-main nk-menu-main will-shrink is-transparent ignore-mask">
                        <div className="container">
                            <div className="nk-header-wrap">
                                <div className="nk-header-logo">
                                    <a href="/" className="logo-link">
                                        <div className="logo-wrap">
                                            <img width="140" src="images/logo-dark-full.png" alt=""/>
                                        </div>
                                    </a>
                                </div>
                                <div className="nk-header-toggle">
                                    <button className="btn btn-light btn-icon header-menu-toggle">
                                        <em className="icon ni ni-menu"></em>
                                    </button>
                                </div>
                                <nav className="nk-header-menu nk-menu">
                                    <ul className="nk-menu-list mx-auto">
                                        <li className="nk-menu-item">
                                            <Link to="/" className="nk-menu-link">
                                                <span className="nk-menu-text">Home</span>
                                            </Link>
                                        </li>
                                        <li className="nk-menu-item">
                                            <Link to="/usecases" className="nk-menu-link">
                                                <span className="nk-menu-text">Use Cases</span>
                                            </Link>
                                        </li>
                                        <li className="nk-menu-item">
                                            <Link to="/features" className="nk-menu-link">
                                                <span className="nk-menu-text">Features</span>
                                            </Link>
                                        </li>
                                        <li className="nk-menu-item">
                                            <Link to="/pricing" className="nk-menu-link">
                                                <span className="nk-menu-text">Pricing</span>
                                            </Link>
                                        </li>
                                        <li className="nk-menu-item">
                                            <Link to="/company" className="nk-menu-link">
                                                <span className="nk-menu-text">About Us</span>
                                            </Link>
                                        </li>
                                    </ul>
                                    <ul className="nk-menu-buttons flex-lg-row-reverse">
                                        <li><Link to="//app.faceview.io/trial" className="btn btn-primary">Sign Up</Link></li>
                                        <li><Link className="link link-dark" to="//app.faceview.io/sign-in">Login </Link></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
    )
}

export default NavBar;