import Home from '../components/home'
import Terms from '../components/terms'
import Faq from '../components/faq'
import Pricing from '../components/pricing'
import Privacy from '../components/privacy'
import UseCases from '../components/usecases'
import Features from '../components/features'
import Cookies from '../components/cookies'
import Contact from '../components/contact'
import Company from '../components/company'

import { createBrowserRouter,} from "react-router-dom";

const AppRouter = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
  },
  {
    path: "/terms",
    element: <Terms/>,
  },
  {
    path: "/faq",
    element: <Faq/>,
  },
  {
    path: "/pricing",
    element: <Pricing/>,
  },
  {
    path: "/privacy",
    element: <Privacy/>,
  },
  {
    path: "/usecases",
    element: <UseCases/>,
  },
  {
    path: "/features",
    element: <Features/>,
  },
  {
      path: "/cookies",
      element: <Cookies/>,
  },
  {
    path: "/contact",
    element: <Contact/>,
  },
  {
    path: "/company",
    element: <Company/>,
  },
  {
      path: "*",
      exact: true,
      element: <Home/>,
    },
]);

export default AppRouter;