import React, {
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";

import NavBar from './navbar'
import Footer from './footer'


const Faq = () => {

    useEffect(()=>{
          document.title = 'Faq';
          window.scrollTo(0,0);
        },[])

    return (

                <div className="nk-app-root ">
                    <NavBar/>
                    <div style={{minHeight: '70px'}}></div>
                    <main class="nk-pages">
                    <section className="section section-bottom-0">
                                            <div className="container">
                                                <div className="section-head">
                                                    <div className="row justify-content-center text-center">
                                                        <div className="col-xl-8">
                                                            <h2 className="title">Frequently Asked Questions</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="section-content">
                                                    <div className="row g-gs justify-content-center">
                                                        <div className="col-xl-9 col-xxl-8">
                                                            <div className="accordion accordion-flush accordion-plus-minus accordion-icon-accent" id="faq-1">
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button" data-bs-toggle="collapse" data-bs-target="#faq-1-1"> What makes Faceview video platform unique? </button>
                                                                    </h2>
                                                                    <div id="faq-1-1" className="accordion-collapse collapse show" data-bs-parent="#faq-1">
                                                                        <div className="accordion-body"> Our platform stands out with features like AI-generated questions, allowing for a more tailored and efficient interviews. We also excel in handling interviews at Scale, making it easy to manage large teams seamlessly. </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#faq-1-2"> How does AI-generated questions benefit my video interviews? </button>
                                                                    </h2>
                                                                    <div id="faq-1-2" className="accordion-collapse collapse" data-bs-parent="#faq-1">
                                                                          <div className="accordion-body"> AI-generated questions ensure consistency and relevance in interviews. The system generates questions based on the job title and description, saving your time and resources. </div>
                                                                     </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#faq-1-3">  Can I organize interviews into projects? </button>
                                                                    </h2>
                                                                    <div id="faq-1-3" className="accordion-collapse collapse" data-bs-parent="#faq-1">
                                                                        <div className="accordion-body"> Absolutely! You can organize interviews as projects, making it easy to categorize, track progress, and manage multiple interviews simultaneously. This feature enhances overall organization and workflow efficiency. </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#faq-1-4">  Is it possible to share and comment on interview recordings? </button>
                                                                    </h2>
                                                                    <div id="faq-1-4" className="accordion-collapse collapse" data-bs-parent="#faq-1">
                                                                        <div className="accordion-body"> Yes, our platform facilitates seamless communication by enabling sharing and commenting on interview recordings. This ensures transparent collaboration and effective feedback exchange among team members. </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                     </main>
                    <Footer/>
                </div>

    )
}

export default Faq;