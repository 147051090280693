import React, {
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";


import NavBar from './navbar'
import Footer from './footer'

const Features = () => {

    useEffect(()=>{
          document.title = 'Features';
          window.scrollTo(0,0);
        },[])

    return (

                <div className="nk-app-root ">
                                    <NavBar/>
                                     <div style={{minHeight: '70px'}}></div>
                                    <main class="nk-pages">
<section class="section section-bottom-0 has-shape has-mask">
                <div class="nk-shape bg-shape-blur-m mt-8 start-50 top-0 translate-middle-x"></div>
                <div class="nk-mask bg-pattern-dot bg-blend-around mt-n5 mb-10p mh-50vh"></div>
                <div class="container">
                    <div class="section-head">
                        <div class="row justify-content-center text-center">
                            <div class="col-lg-8 col-xl-7 col-xxl-6">
                                <h6 class="overline-title text-primary">Features</h6>
                                <h2 class="title h1">Create Your AI Powered <span class="text-primary">Interview Questions</span></h2>
                            </div>
                        </div>
                    </div>
                    <div class="section-content">
                        <div class="row g-gs">
                            <div class="col-md-6 col-xl-4">
                                <div class="card rounded-4 border-0 shadow-tiny h-100">
                                    <div class="card-body">
                                        <div class="feature">
                                            <div class="feature-media">
                                                <div class="media media-middle media-xl media-border text-bg-info-soft-outline rounded-pill">
                                                    <div class=" d-flex align-items-end">
                                                        <em class="icon ni ni-dashboard"></em>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="feature-text">
                                                <h3 class="title">Your Own Job Board</h3>
                                                <p>
                                                 Our platform features an integrated Job Board, enabling employers to showcase all the open positions within the organization, making it easy for candidates to apply through customized application forms.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4">
                                <div class="card rounded-4 border-0 shadow-tiny h-100">
                                    <div class="card-body">
                                        <div class="feature">
                                            <div class="feature-media">
                                                <div class="media media-middle media-xl media-border text-bg-info-soft-outline rounded-pill">
                                                    <em class="icon ni ni-calendar"></em>
                                                </div>
                                            </div>
                                            <div class="feature-text">
                                                <h3 class="title">Video Interviews at Scale</h3>
                                                <p>Our platform is designed to handle the demands of bulk hiring.
                                                Save time, reduce manual efforts, and make data-driven decisions as you discover
                                                 a new era of recruitment efficiency with schedule free Video Interviews </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4">
                                <div class="card rounded-4 border-0 shadow-tiny h-100">
                                    <div class="card-body">
                                        <div class="feature">
                                            <div class="feature-media">
                                                <div class="media media-middle media-xl media-border text-bg-info-soft-outline rounded-pill">
                                                    <em class="icon ni ni-spark"></em>
                                                </div>
                                            </div>
                                            <div class="feature-text">
                                                <h3 class="title">AI Generated Questions</h3>
                                                <p>Leverage the power of artificial intelligence to automatically generate relevant and insightful interview questions.
                                                Enhance the efficiency of your team by ensuring a consistent and targeted set of questions tailored to each interview.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-xl-4">
                                <div class="card rounded-4 border-0 shadow-tiny h-100">
                                    <div class="card-body">
                                        <div class="feature">
                                            <div class="feature-media">
                                                <div class="media media-middle media-xl media-border text-bg-info-soft-outline rounded-pill">
                                                    <div class=" d-flex align-items-end">
                                                        <em class="icon ni ni-box"></em>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="feature-text">
                                                <h3 class="title">Organize as Projects</h3>
                                                <p>Stay organized by structuring your jobs into projects. Categorize and manage interviews efficiently, making it easy to track progress, monitor candidate interactions, and maintain a structured approach to your team's initiatives.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4">
                                <div class="card rounded-4 border-0 shadow-tiny h-100">
                                    <div class="card-body">
                                        <div class="feature">
                                            <div class="feature-media">
                                                <div class="media media-middle media-xl media-border text-bg-info-soft-outline rounded-pill">
                                                    <em class="icon ni ni-book"></em>
                                                </div>
                                            </div>
                                            <div class="feature-text">
                                                <h3 class="title">Contacts Management</h3>
                                                <p>
                                                Effortlessly organize, access, and connect with team members, clients, and collaborators.
                                                Streamline communication and enhance collaboration by having your contacts at your fingertips, making every interaction smooth and efficient
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4">
                                <div class="card rounded-4 border-0 shadow-tiny h-100">
                                    <div class="card-body">
                                        <div class="feature">
                                            <div class="feature-media">
                                                <div class="media media-middle media-xl media-border text-bg-info-soft-outline rounded-pill">
                                                    <em class="icon ni ni-share"></em>
                                                </div>
                                            </div>
                                            <div class="feature-text">
                                                <h3 class="title">Share and Comment</h3>
                                                <p>Facilitate seamless communication within your team by enabling sharing and commenting on interview recordings. Share valuable feedback, exchange thoughts, and collaborate effectively to make informed decisions throughout the process.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
                                     </main>
                                    <Footer/>
                                </div>

    )
}

export default Features;