import React,{Suspense} from 'react';
import AppRouter from './router';
import { RouterProvider,} from "react-router-dom";

function App() {
  return (
      <RouterProvider router={AppRouter} />
  );
}

export default App;
