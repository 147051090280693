import React, {
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";



const Card = () => {

    return (

                <section className="section section-bottom-0">
                                        <div className="container">
                                            <div className="section-wrap bg-primary bg-opacity-10 rounded-4">
                                                <div className="section-content bg-pattern-dot-sm p-4 p-sm-6">
                                                    <div className="row justify-content-center text-center">
                                                        <div className="col-xl-8 col-xxl-9">
                                                            <div className="block-text">
                                                                <h6 className="overline-title text-primary">Transform hiring, boost productivity</h6>
                                                                <h2 className="title">Hire Faster Through Video Interviews</h2>
                                                                <p className="lead mt-3">Break free from timezones and geo locations! Our Platform empowers you to hire talent distributed across different geo locations.
                                                                Embrace the power of schedule free video interviews</p>
                                                                <ul className="btn-list btn-list-inline">
                                                                    <li><a href="//app.faceview.io/trial" className="btn btn-lg btn-primary"><span>Sign up for Free</span><em className="icon ni ni-arrow-long-right"></em></a></li>
                                                                </ul>
                                                                <ul className="list list-row gy-0 gx-3">
                                                                    <li><em className="icon ni ni-check-circle-fill text-success"></em><span>No credit card required</span></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

    )
}

export default Card;