import React, {
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";

import NavBar from './navbar'
import Footer from './footer'
import PricingTable from './pricingtable'

const Pricing = () => {

    useEffect(()=>{
          document.title = 'Pricing';
          window.scrollTo(0,0);
        },[])

    return (

                <div className="nk-app-root ">
                 <NavBar/>
                  <div style={{minHeight: '70px'}}></div>
                 <main class="nk-pages">
                <section class="section section-bottom-0 has-shape has-mask">
                <div class="nk-shape bg-shape-blur-m mt-8 start-50 top-0 translate-middle-x"></div>
                <div class="nk-mask bg-pattern-dot bg-blend-around mt-n5 mb-10p mh-50vh"></div>
                <div class="container">
                    <div class="section-head">
                        <div class="row justify-content-center text-center mt-20">
                            <div class="col-lg-10 col-xl-9 col-xxl-8">
                                <h6 class="overline-title text-primary">Plans &amp; Pricing</h6>
                                <h2 class="title h1">Plans That Best Suit Your Needs</h2>
                            </div>
                        </div>
                    </div>
                    <PricingTable/>
                </div>
            </section>
                                     </main>
                                    <Footer/>
                                </div>

    )
}

export default Pricing;