import React, {
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";

import NavBar from './navbar'
import Hero from './hero'

const Header = () => {

    return (
                <header className="nk-header has-mask">
                    <div className="nk-mask bg-gradient-a"></div>
                    <div className="nk-mask bg-pattern-dot bg-blend-top"></div>
                    <NavBar/>
                    <Hero/>
                </header>
    )
}

export default Header;